<template>
  <div>
    <van-popup v-model="myshow">
      <div class="kefu_box flex-c-c-c">
        <van-image width="3.89rem" height="3.04rem"
                   src="https://oss.xiaoyi120.com/shop2.0/public/kefu.png"
        />
        <div class="kefu_title">{{ title }}</div>
        <div v-if="msg01" class="kefu_time" style="margin-bottom: 0rem;">{{ msg01 }}</div>
        <div class="kefu_time">{{ msg02 }}</div>
        <div class="kefu_btn flex-r-sa-c">
          <div class="kefu_btn_phone flex-r-c-c" @click="phone">
            <van-icon name="phone" size="0.5rem" /><span>电话咨询</span>
          </div>
          <div class="kefu_btn_online flex-r-c-c" @click="online">
            <van-icon name="comment" size="0.5rem" /><span>在线沟通</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'KefuBtn',
  props: {
    title: { type: String, default: '联系客服' },
    msg01: { type: String, default: '' },
    msg02: { type: String, default: '每周一至周五 9:00—17:00' },
    show: { type: Boolean, default: false }
  },
  data() {
    return {
      myshow: this.show,
      phoneNumber: '025-86210186'
    }
  },
  methods: {
    changeShow(isshow) { this.myshow = isshow },
    phone() { window.location.href = 'tel://' + this.phoneNumber },
    online() { window.location.href = 'https://work.weixin.qq.com/kfid/kfc396ef261a54ab364' }
  }
}
</script>

<style lang="scss">
.van-popup {
  border-radius: 0.27rem;
}
.kefu_box {
  width: 8rem;
  height: 8.53rem;
  background: linear-gradient(180deg, #d4e9fa 0%, #ffffff 100%);
  opacity: 1;
  .kefu_title {
    font-size: 0.48rem;
    font-weight: bold;
    line-height: 0.81rem;
    color: #333333;
    padding-top: 0.2rem;
  }
  .kefu_time {
    font-size: 0.37rem;
    color: #999999;
    margin-bottom: 2rem;
  }
  .kefu_btn {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.76rem;
    .kefu_btn_phone {
      height: 0.59rem;
      font-size: 0.43rem;
      color: #333333;
    }
    .kefu_btn_online {
      font-size: 0.43rem;
      color: #0767ab;
    }
    span {
      margin-left: 0.1rem;
    }
  }
}
</style>
