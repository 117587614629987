<template>
  <div class="product_box cart_product_box flex-r-c-c">
    <div class="pro_info flex-r-s-c">
      <van-image width="2.56rem" height="1.92rem" :src="myinfo.proIconUrl" />
      <div class="cart_pro_info">
        <div class="pro_name van-multi-ellipsis--l2">{{ myinfo.productName }}</div>
        <div class="pro_brand_model van-ellipsis">{{ myinfo.proBrand }} | {{ myinfo.proModel }}</div>
        <!-- <div class="pro_tags">1</div> -->
        <div class="pro_bottom flex-r-sb-c">
          <div class="pro_money">￥<span
            style="font-size: 0.49rem;"
          >{{ myinfo.showPrice === 0? myinfo.paidPrice :'详询客服' }}</span></div>
          <div v-if="isChange" class="pro_num_change">
            <van-stepper v-model="myinfo.refundNum" :step="myinfo.minQuantity" :min="myinfo.minQuantity"
                         :max="myinfo.remain" integer input-width="1rem" button-size="0.5rem" @change="changeNum"
            />
          </div>
          <div v-else class="pro_num_change_no" style="font-size: 0.39rem;">x {{ myinfo.refundNum }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: { type: Object, default: () => { } },
    isChange: {type: Boolean, default: true}
  },
  data() {
    return {
      myinfo: this.info
    }
  },
  methods: {
    // 修改数量
    changeNum(val) {
      if (val > parseInt(this.myinfo.refundNum)) {
        val = parseInt(this.myinfo.remain)
        this.$toast({ message: '请重新填写数量！', duration: 1 * 1000 })
        this.$emit('changeNum', val)
      } else {
        this.$emit('changeNum', val)
      }
    }
  }
}
</script>
